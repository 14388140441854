<template>
  <v-app>
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>
<script>
export default {
  mounted () {
    this.$store.dispatch('isSignedIn')
  }
}
</script>
