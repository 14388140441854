import { authAddrs } from '../config'
// import router from '@/router'
import { verifyJWT } from '../jwt'

export const logOut = context => {
  location.replace('https://sinos-auth.web.app/')
}
export const isSignedIn = async context => {
  const token = window.localStorage.getItem('sesuda-token')
  if (token !== null || token?.length !== 0) {
    const verifyToken = await verifyJWT(token)
    if (verifyToken.status !== 'error') {
      context.dispatch('gotoUrl', verifyToken.user)
    } else {
      context.dispatch('logOut')
    }
  } else {
    context.dispatch('logOut')
  }
}

export const gotoUrl = async (context, token) => {
  // default route or selected path
  const defaultApp = token.apps.find(app => app.options.default).options.path
  location.replace(`${authAddrs}/${defaultApp}`)
}
