<template>
  <v-container fill-height>
    <Message />
    <Loading />
  </v-container>
</template>
<script>
import Loading from '../components/Loading.vue'
import Message from '../components/Message.vue'
export default {
  components: {
    Loading,
    Message
  }
}
</script>

<style>
#app {
  background-color: #eeeeee;
}
</style>
