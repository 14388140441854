<template>
  <v-container
    fill-height
    fluid
  >
    <v-row
      justify="center"
      align="center"
    >
      <v-spacer />
      <v-col
        lg="6"
        md="8"
        sm="8"
        cols="12"
      >
        <v-card
          raised
          :loading="true"
        >
          <v-card-text
            class="text-center display-1"
          >
            <v-responsive>
              <v-img
                aspect-ratio="2"
                contain
                :src="logo"
              />
            </v-responsive>
            UNSAM-DATA I+D+i
          </v-card-text>
          <v-footer class="mt-5">
            <v-row
              align="center"
              justify="start"
            >
              <v-col cols="12">
                <v-icon>
                  mdi-email
                </v-icon>
                <span>
                  sinos@unsam.edu.ar
                </span>
              </v-col>
            </v-row>
          </v-footer>
        </v-card>
      </v-col>
      <v-spacer />
    </v-row>
  </v-container>
</template>
<script>
export default {
  computed: {
    logo () {
      return this.$store.state.logo
    }
  }
}
</script>
